/* This SCSS file is responsible for all of the CSS for our reports.
 */
 @import 'brand_colors';
 @import 'risk_colors';
 @import 'globals';
 
 $risk-map-timeline-panel-height-expanded: 170px;
 $legend-item-size: 10px;
 $risk-map-legend-mpa-item-size: 7px;
 $risk-map-timeline-item-size: 30px;
 
 $risk-map-legend-type-item-color: $brand-black;
 $risk-map-panel-scrollbar-color: $brand-dark-grey;
 
 .generic-map-button {
   position: absolute;
   overflow: hidden;
   border-style: none;
   z-index: 100;
   opacity: 1;
 }
 
 .generic-map-button .btn {
   margin-top: 0;
 }
 
 .generic-map-button .fa {
   margin-left: 1px;
 }
 
 .confirmation-popup {
   font-size: initial;
   z-index: 3000 !important;
 }
 
 .report-thumbnail-container {
   display: inline-block;
   white-space: nowrap;
   margin-bottom: 50px;
 }
 
 .report-thumbnail-image {
   display: inline-block;
   min-width: fit-content;
   width: 110px;
 }
 
 .report-thumbnail-link {
   display: inline-block;
   font-weight: bold;
   vertical-align: top;
   white-space: normal;
 }
 
 .report-thumbnail-title-disabled {
   color: $brand-dark-grey;
 }
 
 .report-thumbnail-description {
   margin-top: 5px;
   font-size: calculateRem(12px);
   white-space: initial;
 }
 
 .report-thumbnail-description-disabled {
   color: $brand-medium-grey;
 }
 
 .chart-report-charts-container {
   margin-top: 10px;
 }
 
 .chart-report-chart-warning-title {
   color: #8a6d3b;
   font-size: calculateRem(15px);
 }
 
 .chart-report-chart-error-title {
   color: #ff0002;
   font-size: calculateRem(15px);
 }
 
 .chart-report-chart-warning-error-body {
   color: $brand-black;
   margin-top: 5px;
 }
 
 #chartsChangeSubgroups {
   margin-left: 10px;
 }
 
 .highcharts-data-table table {
   border-collapse: collapse;
   border-spacing: 0;
   background: $brand-white;
   min-width: 100%;
   margin-top: 10px;
   font-family: sans-serif;
   font-size: 0.9em;
 }
 
 .highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
   border: 1px solid silver;
   padding: 0.5em;
 }
 
 .highcharts-data-table thead tr,
 .highcharts-data-table tr:nth-child(even) {
   background: #f8f8f8;
 }
 
 .highcharts-data-table caption {
   border-bottom: none;
   font-size: 1.1em;
   font-weight: bold;
   color: $brand-white;
   background: $brand-dark-grey;
 }
 
 .report-showing-all-span {
   text-align: center;
   margin-top: -10px;
   font-weight: bold;
 }
 
 .report-searching-for {
   font-style: italic;
   margin-left: 5px;
   margin-top: 5px;
 }
 
 #reportSearchClearButton {
   float: right;
   margin-top: -23px;
   margin-right: 5px;
 }
 
 .risk-map-print-note-green {
   color: $brand-highlight-color !important; // !important is for printing
 }
 
 .risk-map-print-note-orange {
   color: darkorange !important; // !important is for printing
 }
 
 .process-map-container {
   height: 100%;
 }
 
 .risk-map-report-container .btn {
   padding-top: 8px;
   padding-bottom: 8px;
 }
 
 .process-map-canvas-container,
 .process-explorer-canvas-container,
 .risk-map-canvas-container {
   background: $brand-white;
   position: relative;
   flex: 1;
   width: 100%;
   font-size: 0;
   overflow: hidden;
 }
 
 .risk-map-canvas-container {
   height: 0;
 }
 
 .process-map-canvas-container,
 .process-explorer-canvas-container {
   height: 100%;
 }
 
 .process-explorer-canvas-container {
   min-height: 200px;
 }
 
 /* For the Tooltip/Popover on the Risk Map Network and Timeline */
 div.vis-tooltip {
   background-color: $brand-white !important;
   -moz-border-radius: 6px !important;
   -webkit-border-radius: 6px !important;
   border-radius: 6px !important;
   border: 1px solid rgba(0, 0, 0, 0.2) !important;
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
   color: $brand-black !important;
   font-family: "Open Sans", sans-serif !important;
 }
 
 .vis-network div.vis-tooltip {
   font-size: calculateRem(16px) !important; /* 16px on a canvas looks like 14px in the editor */
   padding: 8px 15px 10px 15px !important;
 }
 
 .risk-map-timeline div.vis-tooltip {
   padding: 10px 15px 10px 15px !important;
 }
 
 .process-map-tooltip {
   -moz-border-radius: 6px !important;
   -webkit-border-radius: 6px !important;
   background-color: $brand-white;
   border-radius: 6px !important;
   border: 1px solid rgba(0, 0, 0, 0.2) !important;
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
   color: $brand-black;
   display: none;
   font-family: "Open Sans", sans-serif;
   font-size: calculateRem(16px);
   padding: 8px 15px 10px 15px !important;
   pointer-events: none;
   position: absolute;
   white-space: nowrap;
   z-index: 1000;
 }
 
 .risk-map-canvas-container canvas,
 .process-map-canvas-container canvas,
 .process-explorer-canvas-container canvas {
   outline: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }
 
 .process-map-canvas,
 .risk-map-canvas {
   position: relative;
   width: 100%;
   min-height: 100px;
   font-size: 0;
   float: left;
   height: 100%;
 }
 
 .risk-map-report {
   overflow: auto;
   vertical-align: top;
   height: 100%;
 }
 
 .risk-map-network {
   width: 100%;
   height: 100%;
 }
 
 .risk-map-search-typeahead-warning-item a {
   color: red !important;
   font-weight: bold !important;
 }
 
 #riskMapReportTooltipDivider hr {
   margin-top: 10px !important;
   margin-bottom: 10px !important;
 }
 
 .legend-panel {
   background-color: $brand-light-grey !important; // !important is for printing
   border-style: solid;
   border-color: $brand-mild-grey;
   border-width: 1px;
   box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
   padding-top: 44px;
   position: absolute !important;
   text-align: left;
   vertical-align: top;
   white-space: nowrap;
   z-index: 50;
 
   //noinspection Stylelint
   top: 9px;
   right: 9px;
 }
 
 .process-map-legend-panel.legend-panel,
 .canned-report-training-legend-panel.legend-panel {
   right: 19px; // The process map can have a scroll bar that gets in the way of the legend button
 }
 
 /* These next two blocks are borrowed from https://www.tutorialmines.net/bootstrap-collapse/#bootstrap-collapse-horizontal */
 .horizontal-collapsible.collapsing {
   position: relative;
   overflow: hidden;
   transition-duration: 0.5s;
 }
 
 .horizontal-collapsible.collapsing.width {
   transition-property: width, visibility;
   width: 0;
 }
 
 .horizontal-collapsible-content {
   @include thinScrollBar();
 
   height: 100%;
   overflow: auto;
 }
 
 .legend-show-button-div {
   position: absolute !important;
   overflow: hidden;
   border-style: none;
   top: 13px;
   right: 13px;
   z-index: 100;
 }
 
 .legend-close-button-div {
   position: absolute !important;
   border-style: none;
   z-index: 100;
 
   //noinspection Stylelint
   top: -15px;
   left: -10px;
 
   .svg-inline--fa {
     margin-left: 1px;
   }
 }
 
 .process-map-legend-item-row, .risk-map-legend-item-row {
   width: 100%;
   text-align: left;
   margin-bottom: 5px;
 }
 
 .risk-map-legend-content {
   width: 145px;
 
   .risk-map-legend-item-row:last-child {
     margin-bottom: 20px;
   }
 }
 
 .risk-map-legend-child-item {
   margin-left: 5px;
 }
 
 .filter-close-button-div {
   border-style: none;
   position: absolute !important;
   z-index: 100;
 
   //noinspection Stylelint
   top: -15px;
   right: -10px;
 
   .svg-inline--fa {
     margin-left: 1px;
   }
 }
 
 .risk-map-timeline-close-button-div {
   position: absolute !important;
   border-style: none;
   top: -15px;
   right: -9px;
   z-index: 100;
 }
 
 .btn-close.btn-sm {
   width: 18px;
   height: 18px;
   padding: 1px 0 0 0 !important;
   font-size: calculateRem(10px);
   line-height: 1.33;
   border-radius: 25px;
   opacity: 1;
 }
 
 .btn-close.btn-sm span {
   padding: 0 !important;
   margin: 0 !important;
 }
 
 .risk-map-tooltip {
   max-width: 400px;
 }
 
 .process-flow-map-tooltip-header,
 .risk-map-tooltip-header {
   color: $brand-dark-grey;
   font-weight: normal;
   font-size: 0.85em;
 }
 
 .risk-map-tooltip-cs-header {
   color: black;
   font-weight: normal;
   font-size: 0.85em;
 }
 
 .risk-map-tooltip-cs-description {
   color: $brand-dark-grey;
   font-weight: normal;
   font-size: 0.75em;
   white-space: break-spaces;
 }
 
 .process-flow-map-tooltip-value,
 .risk-map-tooltip-value {
   font-weight: normal;
   margin-left: 5px;
   font-size: 0.85em;
   text-align: left;
 }
 
 .risk-map-tooltip-header-value-cell {
   width: 99%;
 }
 
 .risk-type-drop-down {
   width: auto !important;
 }
 
 .risk-calculation-tooltip-table {
   border-collapse: separate;
   border-spacing: 5px;
 }
 
 .risk-calculation-tooltip-cell {
   background: $brand-light-grey;
   padding: 3px 5px;
   margin-bottom: 3px;
   margin-right: 5px;
   text-indent: 0;
 }
 
 .risk-calculation-tooltip-text {
   white-space: nowrap;
 }
 
 .filter-panel {
   background-color: $brand-white !important; // !important is for printing
   border-style: solid;
   border-width: 1px;
   box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
   padding-top: 44px;
   position: absolute !important;
   text-align: left;
   vertical-align: top;
   z-index: 50;
 
   //noinspection Stylelint
   top: 9px;
   left: 9px;
 }
 
 .filter-show-button-div {
   position: absolute !important;
   overflow: hidden;
   border-style: none;
   top: 13px;
   left: 13px;
   z-index: 100;
 }
 
 .filter-show-button-div .btn {
   margin-top: 0;
 }
 
 .process-map-filter-item-row,
 .process-map-filter-item-header,
 .risk-map-filter-item-row,
 .risk-map-filter-item-row-header {
   width: 100%;
   text-align: left;
   font-size: initial;
 }
 
 .process-map-filter-item-row,
 .risk-map-filter-item-row {
   padding-left: 20px;
 }
 
 .process-map-filter-content,
 .risk-map-filter-content {
   width: 290px;
 
   .process-map-filter-item-row:last-child,
   .risk-map-filter-item-row:last-child {
     margin-bottom: 12px;
   }
 }
 
 @media print {
   #riskMapOptionsPanel {
     display: none;
   }
 }
 
 .canvas-report-options-vcenter {
   display: flex;
   flex-direction: row;
   align-items: center;
 
   .wide {
     flex-grow: 4;
   }
 
   .last {
     margin-right: 20px;
   }
 
   .first {
     margin-left: 20px;
   }
 }
 
 .canvas-report-options-panel {
   position: relative;
   width: 100%;
   min-height: 55px;
   border-style: none;
   background-color: $brand-light-grey;
   padding: 5px 10px 3px;
   z-index: 200;
 }
 
 .risk-map-historic-date {
   font-weight: bold;
   font-style: italic;
   color: black;
   margin-left: 5px;
 }
 
 .risk-map-date,
 .risk-map-risk-type,
 .risk-map-effective-rmp {
   margin-left: 5px;
 }
 
 .risk-map-timeline-container {
   position: absolute;
   right: 0;
   bottom: 0;
   left: 0;
 
   .generic-map-button {
     display: inline-block;
     height: fit-content;
   }
 
   .btn {
     padding-left: 11px;
     padding-right: 11px;
   }
 }
 
 .risk-map-timeline-show-button-div {
   margin-bottom: 4px !important;
   top: -58px;
   left: 13px;
 }
 
 .risk-map-graph-zoom-in-button-div {
   margin-bottom: 4px !important;
   top: -100px;
   right: 15px;
 }
 
 .risk-map-graph-zoom-out-button-div {
   margin-bottom: 4px !important;
   top: -58px;
   right: 15px;
 }
 
 .risk-map-timeline-panel {
   background: $brand-white;
   border-style: none;
   box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
   height: 170px;
   margin: 0 auto;
   overflow: visible;
   position: absolute;
   width: calc(100vw - 18px);
 
   /* stylelint-disable declaration-empty-line-before */
   top: auto !important;
   bottom: 9px;
   /* stylelint-enable declaration-empty-line-before */
 }
 
 @media print {
   .risk-map-timeline-panel {
     background: #fff !important;
   }
 }
 
 .canvas-report-options-button .svg-inline--fa {
   margin-left: 1px;
 }
 
 .risk-map-timeline-scale-to-fit-button-div {
   margin-bottom: 4px !important;
   top: -50px;
   right: 54px;
   display: none;
 }
 
 .risk-map-timeline-warning-div {
   top: -50px;
   left: 60px;
   margin-bottom: 10px !important;
   min-height: 35px;
   padding: 7px;
   position: absolute;
   right: calc(50vw + 60px);
 }
 
 .risk-map-timeline-warning-show-all {
   float: right;
   margin-left: 10px;
 }
 
 .risk-map-timeline-warning-div.collapsed {
   bottom: 0 !important;
   display: none;
 }
 
 .risk-map-timeline-warning-div.expanded {
   bottom: $risk-map-timeline-panel-height-expanded;
   display: inherit;
 }
 
 .risk-map-timeline-first-button-div {
   margin-bottom: 4px !important;
   top: -50px;
   left: calc(50vw - 60px);
   display: none;
 }
 
 .risk-map-timeline-previous-button-div {
   margin-bottom: 4px !important;
   top: -50px;
   left: calc(50vw - 20px);
   display: none;
 }
 
 .risk-map-timeline-next-button-div {
   margin-bottom: 4px !important;
   top: -50px;
   left: calc(50vw + 20px);
   display: none;
 }
 
 .risk-map-timeline-last-button-div {
   margin-bottom: 4px !important;
   top: -50px;
   left: calc(50vw + 60px);
   display: none;
 }
 
 .canvas-report-options-content {
   position: relative;
   font-size: initial;
   height: 100%;
   width: 100%;
   color: $brand-dark-grey !important;
 }
 
 .risk-map-timeline-content {
   position: relative;
   font-size: initial;
   overflow: visible;
   height: 100%;
 }
 
 .risk-map-timeline {
   width: 100%;
   height: 100%;
 }
 
 .risk-map-timeline-loading-panel {
   height: 100%;
   text-align: center;
   width: 100%;
 }
 
 .risk-map-timeline-loading-img {
   margin-top: 20px;
 }
 
 .risk-map-expand-timeline-button {
   font-size: calculateRem(24px);
 }
 
 .flyout-height-transition {
   -moz-transition: height 2.5s;
   -o-transition: height 2.5s;
   -webkit-transition: height 2.5s !important;
   transition: height 2.5s;
   overflow: visible;
 }
 
 .risk-map-layout-delete-image {
   cursor: pointer;
 }
 
 .risk-map-popup-layout-container {
   font-size: initial;
 }
 
 .canvas-report-options-row {
   flex-wrap: wrap;
   width: 100%;
   height: 100%;
 }
 
 .risk-map-search-typeahead {
   width: 100%;
   padding-right: 5px;
 }
 
 .process-map-filter-field,
 .risk-map-filter-field {
   position: relative;
   display: flex;
   flex-wrap: nowrap;
   align-self: center;
   width: 100%;
   padding-left: 15px;
   padding-right: 15px;
   font-size: calculateRem(12px);
 }
 
 .process-map-filter-field label,
 .risk-map-filter-field label {
   margin-bottom: 0;
 }
 
 .process-map-filter-field .col-form-label,
 .risk-map-filter-field .col-form-label {
   padding-top: 0;
   padding-bottom: 0;
 }
 
 .risk-map-color-showing-div {
   margin-top: 10px;
 }
 
 .risk-map-color-showing-label {
   color: black !important;
 }
 
 .process-map-filter-label,
 .risk-map-filter-label {
   font-weight: bold;
   margin-bottom: 0;
   white-space: nowrap;
 }
 
 .filter-reset-link {
   position: absolute !important;
   border-style: none;
   text-align: right;
   padding-right: 20px;
   width: 100%;
   margin-top: 10px;
   margin-bottom: 10px;
   font-weight: bold;
   overflow: hidden;
   white-space: nowrap;
   font-size: initial;
 
   /* stylelint-disable declaration-empty-line-before */
   top: 0;
   /* stylelint-enable declaration-empty-line-before */
 }
 
 .canvas-report-options-field {
   position: relative;
   width: auto;
   float: left;
   font-size: initial;
   margin: 5px;
 
   input[type=checkbox], label {
     vertical-align: middle;
     margin: 0;
   }
 }
 
 .canvas-report-options-field-sm {
   font-size: calculateRem(12px);
 }
 
 .canvas-report-options-field-md {
   font-size: calculateRem(14px);
 }
 
 .canvas-report-options-field-top {
   align-self: normal;
 }
 
 .canvas-report-options-field-container {
   position: relative;
   width: auto;
   float: left;
   font-size: initial;
   margin: 0;
 }
 
 .canvas-report-options-right-align {
   margin-left: auto;
 }
 
 .canvas-report-options-field .btn {
   margin-top: 0 !important;
 }
 
 .divider-vertical {
   height: 20px;
   margin-top: 11px;
   margin-bottom: 11px;
   border-left: 1px solid grey;
   border-right: 1px solid grey;
   padding: 0;
 }
 
 .divider-vertical-sm-top {
   height: 15px;
   margin-top: 6px;
   border-left: 1px solid grey;
   border-right: 1px solid grey;
   padding: 0;
   align-self: normal;
 }
 
 @media print {
   .divider-vertical,
   .divider-vertical-sm-top {
     display: none;
   }
 }
 
 .risk-map-options-label {
   position: relative;
   width: auto;
   float: left;
   font-size: initial;
   margin: 10px 5px 5px;
 }
 
 .risk-map-options-stacked-field {
   position: relative;
   align-self: center;
   width: auto;
 }
 
 .risk-map-search-label {
   width: auto;
   align-self: center;
 }
 
 .risk-map-radio-filter {
   white-space: nowrap;
 }
 
 .risk-map-checkbox-filter {
   white-space: nowrap;
 }
 
 .risk-map-radio-filter .col-form-label {
   display: block;
 }
 
 .risk-map-options-search-field {
   min-width: 300px;
   display: flex;
 }
 
 .unit-operations-select-all-checkbox, .raw-materials-select-all-checkbox {
   display: inline-block;
   height: calculateRem(12px);
 }
 
 .unit-operations-select-all-checkbox input, .raw-materials-select-all-checkbox input {
 }
 
 .unit-operations-select-all-checkbox-label, .raw-materials-select-all-checkbox-label {
   display: inline-block;
 }
 
 .unit-operations-select-all-checkbox-label label, .raw-materials-select-all-checkbox-label label {
   margin-bottom: 0;
 }
 
 .filter-item-row-last {
   margin-bottom: 20px;
 }
 
 .process-map-legend-show-button-div {
   right: 23px;
 }
 
 .process-map-legend-content {
   width: 200px;
 }
 
 .process-map-legend-item-row-separator, .risk-map-legend-item-row-separator {
   height: 2px;
   width: 100%;
   background: $brand-white;
 }
 
 .process-map-legend-category, .risk-map-legend-category {
   font-size: calculateRem(14px);
   font-weight: bold;
   text-align: left;
   margin-left: 15px;
   margin-top: 15px;
 }
 
 .process-map-filter-panel,
 .risk-map-filter-panel {
   max-height: calc(100% - #{$risk-map-timeline-item-size});
 }
 
 .process-map-filter-header,
 .risk-map-filter-header {
   font-size: calculateRem(14px);
   font-weight: bold;
   margin-top: 10px;
   padding-left: 15px;
   padding-right: 5px;
   text-align: left;
   white-space: nowrap;
   color: $brand-black;
 }
 
 .process-map-filter-category,
 .risk-map-filter-category {
   align-items: center;
   display: flex;
   font-size: calculateRem(12px);
   font-weight: bold;
   margin-top: 8px;
   margin-bottom: 6px;
   padding-right: 5px;
   text-align: left;
   white-space: nowrap;
   color: $brand-black;
 }
 
 .process-map-filter-content {
   width: 310px;
 
   > div:last-child {
     margin-bottom: 20px;
   }
 }
 
 .process-map-legend-item-description, .risk-map-legend-item-description {
   font-size: calculateRem(12px);
   font-weight: bold;
   display: inline-block;
 }
 
 .risk-map-legend-item-parent {
   margin-bottom: 5px;
 }
 
 .risk-map-legend-item-description-approved {
   width: 100px;
   border-style: solid;
   border-width: 1px;
   margin-left: 9px;
   margin-bottom: 5px;
   text-align: center;
 }
 
 .risk-map-legend-item-description-draft::before {
   content: "";
   position: absolute;
   border: 5px dashed $brand-black;
   top: -4px;
   bottom: -4px;
   left: -4px;
   right: -4px;
 }
 
 .risk-map-legend-item-description-draft {
   overflow: hidden;
   position: relative;
   width: 100px;
   margin-left: 9px;
   text-align: center;
 }
 
 .risk-map-legend-item-text-wrapper {
   margin-left: 15px;
   margin-right: 15px;
 }
 
 @mixin riskMapTimelineItemIQAContent($colors, $borderColors, $color-index, $size, $border-size) {
   position: relative;
   width: $size !important;
   height: calc(#{$size} * 0.5774);
   margin-top: 7px !important;
   margin-bottom: 7px !important;
   margin-left: calc(50% - (#{$size} / 2));
   border-left: solid calc(1px * #{$border-size}) nth($borderColors, $color-index) !important;
   border-right: solid calc(1px * #{$border-size}) nth($borderColors, $color-index) !important;
 
   &::before, &::after {
     content: "";
     position: absolute;
     z-index: 1;
     width: calc(#{$size} * 0.7071);
     height: calc(#{$size} * 0.7071);
     left: calc((#{$size} * 0.1465));
     background: nth($colors, $color-index) !important;
     margin-left: calc(50% - (#{$size} / 2));
   }
 
   &::before {
     top: calc(-#{$size} * 0.3536);
     border-bottom: solid calc(1.4142px * #{$border-size}) nth($borderColors, $color-index) !important;
     border-left: solid calc(1.4142px * #{$border-size}) nth($borderColors, $color-index) !important;
     -webkit-transform: scaleY(0.5774) rotate(135deg) !important;
     -ms-transform: scaleY(0.5774) rotate(135deg) !important;
     transform: scaleY(0.5774) rotate(135deg) !important;
   }
 
   &::after {
     bottom: calc(-#{$size} * 0.3536);
     border-bottom: solid calc(1.4142px * #{$border-size}) nth($borderColors, $color-index) !important;
     border-left: solid calc(1.4142px * #{$border-size}) nth($borderColors, $color-index) !important;
     -webkit-transform: scaleY(0.5774) rotate(-45deg);
     -ms-transform: scaleY(0.5774) rotate(-45deg);
     transform: scaleY(0.5774) rotate(-45deg);
   }
 }
 
 @mixin riskMapTimelineItemPPContent($colors, $borderColors, $color-index, $size, $border-size) {
   width: $size;
   height: calc($size * 23 / 30);
   display: inline-block;
   background-color: transparent !important;
   margin-top: 3px;
   margin-left: 15px !important;
   margin-right: 15px !important;
 
   $border-real-size: calc($size * 2 / 3) + $border-size;
   $complex-border-size : $border-real-size - (2 * $border-size);
 
   &::after, &.border::before {
     position: absolute;
     content: "";
     width: 0;
     height: 0;
     left: calc(50% - #{$complex-border-size});
   }
 
   &.border::before {
     z-index: -1;
     margin-top: (-$border-size * 2);
     margin-left: (-$border-size * 2);
   }
 
   &.up::after {
     border-bottom: $size - (3 * $border-size) solid nth($colors, $color-index);
     border-left: $border-real-size - (2 * $border-size) solid transparent;
     border-right: $border-real-size - (2 * $border-size) solid transparent;
   }
 
   &.up::before {
     border-bottom: $size solid nth($borderColors, $color-index);
     border-left: $border-real-size solid transparent;
     border-right: $border-real-size solid transparent;
   }
 }
 
 @mixin riskMapTimelineItemMABorder($colors, $color-index, $size) {
   background: transparent !important;
   position: relative;
   display: block;
   margin-top: 5px;
   margin-bottom: 9px;
   margin-left: calc(50% - (#{$size} * 0.8));
   width: 0;
   height: 0;
   border-right: $size * 0.8 solid transparent !important;
   border-bottom: ($size * 0.8 * 0.75) solid nth($colors, $color-index) !important;
   border-left: $size * 0.8 solid transparent !important;
   -webkit-transform: rotate(37deg);
   -moz-transform: rotate(37deg);
   -ms-transform: rotate(37deg);
   -o-transform: rotate(37deg);
   transform: rotate(37deg);
 
   &::before {
     position: absolute;
     display: block;
     width: 0;
     height: 0;
     top: -($size * 0.8 * 0.47);
     left: -($size * 0.8 * 0.6);
     content: "";
     border-bottom: ($size * 0.8 * 0.7) solid nth($colors, $color-index) !important;
     border-left: ($size * 0.8 * 0.24) solid transparent !important;
     border-right: ($size * 0.8 * 0.24) solid transparent !important;
     -webkit-transform: rotate(323deg);
     -moz-transform: rotate(323deg);
     -ms-transform: rotate(323deg);
     -o-transform: rotate(323deg);
     transform: rotate(323deg);
   }
 
   &::after {
     position: absolute;
     display: block;
     width: 0;
     height: 0;
     top: 0;
     left: -$size * 0.8;
     content: "";
     border-right: $size * 0.8 solid transparent !important;
     border-bottom: ($size * 0.8 * 0.75) solid nth($colors, $color-index) !important;
     border-left: $size * 0.8 solid transparent !important;
     -webkit-transform: rotate(286deg);
     -moz-transform: rotate(286deg);
     -ms-transform: rotate(286deg);
     -o-transform: rotate(286deg);
     transform: rotate(286deg);
   }
 }
 
 $legendItemColors: $brand-black;
 $legendItemBorderColors: $brand-black;
 
 .process-map-legend-shape, .risk-map-legend-shape {
   width: $legend-item-size;
   height: $legend-item-size;
   border-style: none;
   margin-left: 16px !important;
   margin-right: 14px !important;
   margin-top: 3px !important;
   float: left;
   position: relative;
 }
 
 .process-map-legend-shape {
   background: $risk-table-cell-very-high-color !important;
 }
 
 .risk-map-legend-shape {
   background: $risk-map-legend-type-item-color !important; // !important is for printing
 }
 
 .process-map-legend-fqa, .risk-map-legend-fqa {
   -moz-border-radius: calc(#{$legend-item-size} / 2);
   -webkit-border-radius: calc(#{$legend-item-size} / 2);
   border-radius: calc(#{$legend-item-size} / 2);
 }
 
 .process-map-legend-shape.risk-map-legend-iqa, .risk-map-legend-shape.risk-map-legend-iqa {
   margin-top: 5px !important;
 }
 
 .risk-map-iqa-legend-parent {
   transform: rotate(30deg);
   float: left;
 }
 
 .process-map-legend-iqa, .risk-map-legend-iqa {
   @include riskMapTimelineItemIQAContent($legendItemColors, $legendItemBorderColors, 1, $legend-item-size, 1);
 }
 
 .risk-map-pp {
   @include riskMapTimelineItemPPContent($legendItemColors, $legendItemBorderColors, 1, $legend-item-size, 0);
 }
 
 .risk-map-legend-shape.risk-map-legend-ma {
   margin-left: 15px !important;
   margin-right: 13px !important;
   margin-top: 5px !important;
 }
 
 .risk-map-legend-ma {
   @include riskMapTimelineItemMABorder($legendItemBorderColors, 1, $legend-item-size * 0.8);
 }
 
 .risk-map-legend-risk-shape {
   border-style: solid !important;
   margin-top: 4px !important;
 }
 
 .process-map-legend-item-text {
   border-style: none;
   display: inline-block;
   font-size: calculateRem(12px);
   margin-left: 16px !important;
   margin-right: 14px !important;
   width: 20px;
 }
 
 .risk-map-catastrophic {
   background: $risk-map-veryhigh-regular-color !important;
   border-color: $risk-map-veryhigh-regular-outline;
 }
 
 .risk-map-critical {
   background: $risk-map-high-regular-color !important;
   border-color: $risk-map-high-regular-outline;
 }
 
 .risk-map-moderate {
   background: $risk-map-medium-regular-color !important;
   border-color: $risk-map-medium-regular-outline;
 }
 
 .risk-map-minor {
   background: $risk-map-low-regular-color !important;
   border-color: $risk-map-low-regular-outline;
 }
 
 .risk-map-not-evaluated {
   background: $risk-map-not-evaluated-regular-color !important;
   border-color: $risk-map-not-evaluated-regular-outline;
 }
 
 .risk-map-not-assessed {
   background: $risk-map-not-assessed-regular-color !important;
   border-color: $risk-map-not-assessed-regular-outline;
 }
 
 .risk-map-negligible {
   background: $risk-map-very-low-regular-color !important;
   border-color: $risk-map-very-low-regular-outline;
 }
 
 .risk-map-timeline-item-container {
   background: transparent !important;
   border: none !important;
   height: 70px;
 }
 
 .risk-map-timeline-item-legend {
   font-weight: normal;
   font-family: 'Open Sans', sans-serif;
   font-size: calculateRem(12px);
   position: relative;
   top: -10px;
 }
 
 .vis-item.vis-selected .risk-map-timeline-item-legend {
   font-weight: bold !important;
   font-family: 'Open Sans', sans-serif;
   font-size: calculateRem(16px);
 }
 
 .risk-map-timeline-select-time-bar {
   background-color: $brand-primary-color !important;
   width: 3px !important;
 }
 
 .risk-map-timeline-rmp-custom-time {
   z-index: 0 !important;
   background-color: #c30 !important;
   width: 3px !important;
 }
 
 .risk-map-timeline-custom-time-project {
   z-index: 0 !important;
   background-color: $brand-medium-grey !important;
   width: 3px !important;
 }
 
 .risk-map-timeline-custom-time-rmp-legend {
   font-size: calculateRem(14px);
   white-space: nowrap;
   margin-left: 10px;
   margin-top: 10px;
   color: #c30;
 }
 
 .risk-map-timeline-custom-time-project-legend {
   font-size: calculateRem(14px);
   white-space: nowrap;
   margin-left: 10px;
   margin-top: 10px;
   color: $brand-dark-grey;
 }
 
 .risk-tables-report-header {
   display: inline-block;
 }
 
 .risk-tables-report-header-date-label {
   display: inline-block;
   margin-left: 10px;
 }
 
 .risk-tables-report-header-date {
   display: inline-block;
   margin-left: 5px;
   font-style: italic;
 }
 
 .risk-tables-report-table-th,
 .risk-tables-report-table-firstCell {
   background-color: $brand-white;
 }
 
 .risk-tables-select-label {
   font-weight: bold;
 }
 
 .btn-group .btn-report {
   margin-right: 10px;
   margin-top: 13px;
   margin-bottom: 0;
   font-size: calculateRem(16px);
 }
 
 .react-datepicker-popper {
   z-index: 10;
 }
 
 .data-btn-group {
   float: right;
   margin-top: 6px;
 }
 
 .data-report-btn-group {
   padding-top: 16px;
 
   @media (max-width: 768px) {
     min-width: 280px;
   }
 }
 
 .data-report-no-padding-bottom {
   padding-bottom: 0;
 }
 
 .data-report-filter {
   margin-top: 5px;
 }
 
 .data-report-filter-title {
   color: black;
 }
 
 .data-report-print-button .svg-inline--fa {
   margin-right: 5px;
 }
 
 .data-report-split-button {
   margin-left: 5px;
 }
 
 .data-report-filter-container,
 .data-report-criticality-filter-container {
   border-radius: 4px;
   border: 1px solid $brand-mild-grey;
   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
   height: 145px;
   margin-bottom: 20px;
   overflow-y: auto;
   padding-bottom: 5px;
   padding-top: 5px;
 }
 
 .data-report-filter-option {
   padding-left: 10px;
 }
 
 #dataReportViewer {
   max-width: 1170px;
 }
 
 .stiJsViewerBookmarksContainerSimple {
   display: none;
 }
 
 .chart-report-container {
   background: lightgrey;
 }
 
 .chart-report-container-default {
   padding: 20px 20px 0 20px;
   margin: 0;
 }
 
 .chart-report-container-last-chart {
   padding-bottom: 30px;
 }
 
 #StiViewerReportPanel {
   overflow: visible !important;
 }
 
 .capability-plot-info {
   background: $brand-white;
   height: fit-content;
   margin-top: 10px;
   padding: 15px;
 }
 
 @media (max-width: 1199.9px) {
   .capability-plot-info {
     padding: 30px;
   }
 }
 
 .capability-plot-info-title {
   font-size: calculateRem(16px);
   font-weight: bold;
   text-align: center;
 }
 
 .chart-reports-validation-icon {
   position: absolute;
   z-index: 100;
   top: 19px;
   right: 47px;
 }
 
 #processCapabilityDashboard, #chartReportsPage {
   #bodyDiv {
     display: flex;
     flex-flow: column;
     position: relative;
     height: 100vh;
   }
 
   .flyout-panel {
     height: 100%;
     position: static;
   }
 
   .flyout-left-panel div.container, .flyout-left-panel div.container-fluid {
     width: calc(100vw - #{$scrollbar-size}); // so the left panel doesn't squeeze when the right opens
     padding-bottom: 30px;
   }
 
   .gutter, .flyout-right-panel {
     z-index: 100;
   }
 
   .flyout-right-panel {
     z-index: 100;
     background: $brand-white;
     overflow: auto;
   }
 
   .pcm-dashboard-flyout-collapsed {
     .gutter {
       width: 0;
       display: none;
       border: 0;
     }
 
     .flyout-right-panel {
       display: none;
     }
   }
 }
 
 .pcm-dashboard-container, .chart-report-background-container {
   background-color: $brand-light-grey;
 }
 
 .pcm-dashboard-header-sub-title {
   font-weight: bold;
   color: black;
   padding-left: 15px;
 }
 
 .pcm-dashboard-search-label {
   font-size: calculateRem(14px);
   color: black;
   margin-top: 6px;
   font-weight: normal;
   padding-right: 10px;
 }
 
 .pcm-dashboard-toolbar-btn-container {
   align-items: center;
   display: flex;
   justify-content: flex-end;
 
   .split-button {
     margin-top: 0;
   }
 }
 
 .pcm-dashboard-toolbar-container {
   background: #e3e3e3;
   padding: 15px 40px;
 }
 
 .pcm-dashboard-radio-filter {
   color: $brand-white;
   font-size: calculateRem(12px);
   padding-left: 25px;
   font-weight: normal;
 }
 
 .pcm-dashboard-toolbar-btn {
   margin: -2px 0 0;
 }
 
 .pcm-dashboard-typeahead {
   flex: auto;
 }
 
 .pcm-dashboard-index-container {
   height: 130px;
   background: #daeff7;
   border-right: solid $brand-white 1px;
 }
 
 .pcm-dashboard-canvas-container {
   position: relative;
   flex: 1;
   width: 100%;
   font-size: 0;
   overflow: hidden;
   background: $brand-white;
 }
 
 .pcm-dashboard-canvas-left,
 .pcm-dashboard-canvas-right {
   position: relative;
   width: 50%;
   min-height: 100px;
   font-size: 0;
   float: left;
   height: 100%;
   display: flex;
   flex-wrap: wrap;
   padding: 4px 1px 5px 4px;
 }
 
 .pcm-dashboard-canvas-right {
   padding-right: 4px;
   padding-left: 2px;
   padding-bottom: 4px;
 }
 
 .pcm-dashboard-index-chart {
   background-color: #effafe;
   height: 180px; // If you change this, fix the height of the capability chart too in `PCMDashboardPage.handleReceivedDataFromServer()`
   min-width: 200px;
   display: flex;
   flex: 1 1 33.333333333%;
   border: solid $brand-white 1px;
   flex-direction: column;
   align-items: center;
 }
 
 .pcm-dashboard-info-box {
   background-color: #daeff7;
   justify-content: center;
   min-height: 110px;
   min-width: 200px;
   display: flex;
   flex: 1 1 33.333333333%;
   border: solid $brand-white 1px;
   flex-direction: column;
   align-items: center;
 }
 
 .pcm-dashboard-index-chart-white-background {
   background: $brand-white;
 }
 
 .pcm-dashboard-index-chart-top-margin {
   margin-top: 40px;
 }
 
 .pcm-dashboard-index-chart-title,
 .pcm-dashboard-info-box-title {
   color: black;
   font-weight: normal;
   margin-top: 10px;
   font-size: calculateRem(16px);
 }
 
 .pcm-dashboard-index-chart-value,
 .pcm-dashboard-info-box-value {
   color: black;
   font-size: calculateRem(24px);
 }
 
 .pcm-dashboard-index-chart-label {
   position: relative;
   width: auto;
   float: left;
   font-size: initial;
   margin-left: 5px;
   margin-right: 5px;
   margin-bottom: 5px;
 }
 
 .pcm-dashboard-index-chart-plot-area-minified {
   height: 75px;
   width: 100%;
 }
 
 .pcm-dashboard-index-chart-plot-area {
   height: 200px;
   width: 100%;
 }
 
 .pcm-dashboard-last-subgroups,
 .pcm-dashboard-control-charts {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   background: #effafe;
   min-width: 200px;
   border-right: solid $brand-white 1px;
 }
 
 .pcm-dashboard-control-charts {
   background: #f3f7f8;
   border-left: solid $brand-white 1px;
   border-top: solid $brand-white 1px;
 }
 
 .pcm-dashboard-control-charts-last {
   background: $brand-light-grey;
   border-top: solid $brand-white 2px;
 }
 
 .pcm-dashboard-control-chart {
   padding-left: 36px;
   padding-right: 36px;
   padding-bottom: 31px;
   border-top: solid $brand-white 0;
 }
 
 .pcm-dashboard-control-chart-title {
   padding-left: 30px;
   padding-top: 15px;
   color: black;
 }
 
 .pcm-chart-plot-line-single-bar,
 .pcm-chart-plot-line-double-bar {
   text-decoration: overline;
 }
 
 .pcm-chart-plot-line-double-bar {
   text-decoration-style: double;
 }
 
 .pcm-dashboard-error-bar {
   margin: 5px 0 5px 0;
 }
 
 .pcm-typeahead-marker {
   color: #898b8b;
 }
 
 .data-report-border {
   border-bottom: solid $brand-dark-grey 1px;
 }
 
 .pcm-dashboard-canvas-transparent-container {
   height: 500px;
   background: transparent;
 }
 
 .pcm-dashboard-export-button {
   margin-top: -7px;
   margin-right: 15px;
 }
 
 #pcmDashboardControlChartsButton {
   font-weight: bold;
 }
 
 .pcm-dashboard-process-data-table, .pcm-dashboard-process-data-table-right {
   background: $brand-white;
   margin: 10px 0;
   overflow: auto;
   padding: 0 20px 20px 20px;
 
   .dataTables_filter {
     float: right;
     margin-top: 12px;
   }
 }
 
 .pcm-dashboard-data-apply-button {
   float: left;
   margin-bottom: 15px;
 }
 
 .pcm-dashboard-process-data-table-title {
   padding-top: 20px;
 }
 
 .pcm-dashboard-process-data-table > div > .dataTables_wrapper > div > table.dataTable {
   margin-bottom: 20px !important;
 }
 
 .data-report-viewer-full-screen {
   background-color: $brand-white;
   max-width: 100% !important;
   margin-left: auto;
   margin-right: auto;
 }
 
 .canned-report-container {
   margin-bottom: 20px;
   padding-left: 0;
   padding-right: 0;
   padding-bottom: 0;
 }
 
 .canned-report-container-full-screen {
   margin-top: 20px;
   margin-left: 15px;
   margin-right: 15px;
 }
 
 .canned-report-container-full-screen-header {
   margin-left: 15px;
   padding-bottom: 0;
 }
 
 .qtpp-full-report-iframe {
   visibility: hidden;
 }
 
 .risk-map-error-bar {
   margin: 10px 20px 0 15px;
 }
 
 .thumbnail-overlay {
   background: rgba($brand-mild-grey, 0.6);
   color: black;
   display: block;
   left: 0;
   top: 0;
   position: absolute;
   margin-left: 15px;
   text-align: center;
   font-weight: bold;
   padding-top: 24px;
 }
 
 .document-training-report-label {
   display: block;
   width: 100px;
 }
 
 .training-status-help-div {
   display: inline-block;
   margin-right: 5px;
 }
 
 .training-status-help-button {
   border: none;
 }
 
 #trainingStatusIcon.btn-secondary:hover {
   color: $hover-color;
 }
 
 .training-status-help-button:hover {
   box-shadow: none;
   background: $brand-light-grey;
 }
 
 .training-status {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   margin-top: 18px;
 
   .training-status-shape {
     -moz-border-radius: calc(#{$confidence-level-item-size} / 2);
     -webkit-border-radius: calc(#{$confidence-level-item-size} / 2);
     border-radius: calc(#{$confidence-level-item-size} / 2);
     width: $confidence-level-item-size;
     height: $confidence-level-item-size;
     border-style: none;
     margin-top: 2px;
   }
 
   .training-status-grey-shape {
     background-color: $brand-light-grey;
     border: thin solid $brand-dark-grey;
   }
 
   .training-status-yellow-shape {
     background-color: #fff9e6;
     border: thin solid $brand-dark-grey;
   }
 
   .training-status-red-shape {
     background-color: #fbeaec;
     border: thin solid $brand-dark-grey;
   }
 
   .training-status-green-shape {
     background-color: #ebf9f7;
     border: thin solid $brand-dark-grey;
   }
 
   .training-status-white-shape {
     background-color: white;
     border: thin solid $brand-dark-grey;
   }
 
   .training-status-shape-text {
     margin-left: 12px;
 
     .training-status-shape-label {
       font-size: calculateRem(14px);
       font-weight: normal;
     }
 
     .training-status-shape-description {
       font-size: calculateRem(12px);
       font-weight: normal;
       width: 245px;
       color: $brand-dark-grey;
     }
   }
 }
 