/*
  Use bootstrap CSS when you can! (https://getbootstrap.com/docs/3.3/css/#helper-classes)

  Company colors:
    See our brand colors here: https://coolors.co/087d88-37ad8f-acba37-606060-e0e0e0

  Loading image is from: http://www.chimply.com/Generator#classic-spinner,animatedTurningIndicator
*/
@import 'admin';
@import 'bootstrap_overrides';
@import 'bulk_propose_approve';
@import 'editables';
@import 'footer';
@import 'headers';
@import 'home_page';
@import 'import';
@import 'login_create_account';
@import 'process_explorer';
@import 'quick_panel';
@import 'training';
@import 'reports';
@import 'batches';
@import 'rmp';
@import 'tables';
@import 'tech_transfer';
@import 'telerik_grid_overrides';
@import 'widgets';
@import 'utils';
@import 'library';
@import 'configurable_tables';

/* This is the common css file that every page will have */
//noinspection CssUnknownTarget
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600|Roboto:100,400,700,900);

@font-face {
  font-family: old-stamper;
  src: url("/fonts/old_stamper.ttf");
}
